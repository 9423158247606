/*
 * @Description: 路由配置
 * @Author: AlanGao
 * @Date: 2024-05-15 19:51:07
 * @LastEditors: AlanGao
 * @LastEditTime: 2024-05-18 20:21:25
 */
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('../views/layout/index.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/index.vue'),
      },
      {
        path: '/company_profile',
        name: 'company_profile',
        component: () => import('../views/about/company_profile.vue'),
      },
      // {
      //   path: '/company_employee',
      //   name: 'company_employee',
      //   component: () => import('../views/about/company_employee.vue'),
      // },
      {
        path: '/elegant_demeanor',
        name: 'elegant_demeanor',
        component: () => import('../components/center.vue'),
      },
      {
        path: '/enterprise_advantage',
        name: 'enterprise_advantage',
        component: () => import('../components/center.vue'),
      },
      {
        path: '/video',
        name: 'video',
        component: () => import('../views/video/index.vue'),
      },
      {
        path: '/case_presentation',
        name: 'case_presentation',
        component: () => import('../views/case/case_presentation.vue'),
      },
      {
        path: '/gk_service',
        name: 'gk_service',
        component: () => import('../views/server/gk_service.vue'),
      },
      {
        path: '/gk1_path',
        name: 'gk1_path',
        component: () => import('../views/learn/gk1_path.vue'),
      },
      {
        path: '/gk2_path',
        name: 'gk2_path',
        component: () => import('../views/learn/gk2_path.vue'),
      },
      {
        path: '/gk3_path',
        name: 'gk3_path',
        component: () => import('../views/learn/gk3_path.vue'),
      },
     
      {
        path: '/course_industrial_control',
        name: 'course_industrial_control',
        component: () => import('../views/course/course_industrial_control.vue'),
      },
      {
        path: '/digital_twin',
        name: 'digital_twin',
        component: () => import('../components/center.vue'),
      },
      {
        path: '/internet',
        name: 'internet',
        component: () => import('../components/center.vue'),
      },
      {
        path: '/cognitive_enhancement',
        name: 'cognitive_enhancement',
        component: () => import('../components/center.vue'),
      },
      {
        path: '/auto_ai',
        name: 'auto_ai',
        component: () => import('../components/center.vue'),
      },
      {
        path: '/other_accessories',
        name: 'other_accessories',
        component: () => import('../views/course/other_accessories.vue'),
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
