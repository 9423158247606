/*
 * @Description: 入口文件
 * @Author: AlanGao
 * @Date: 2024-05-15 19:51:07
 * @LastEditors: AlanGao
 * @LastEditTime: 2024-05-18 20:10:31
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Slick from 'slick-carousel'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// 引入全局样式
import '/public/assets/styles/global.css';
import $ from 'jquery'
import PublicCenter from './components/center.vue'

window.$ = $
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('PublicCenter',PublicCenter)
app.use(ElementPlus)
app.use(Slick)
app.use(router)
app.mount('#app')
