export const menuData = [
    {id: '1',name: '网站首页', path: '/home'},
    {id: '3',name: '课程中心', path: '',children: [
        {id: "3-1",name: '工控课程系列', path: '/course_industrial_control' },
        {id: "3-2",name: '万物互联系列', path: '/internet'},
        {id: "3-3",name: '数字孪生系列', path: '/digital_twin' },
        {id: "3-4",name: '人工智能系列', path: '/auto_ai'},
        {id: "3-5",name: '认知提升系列', path: '/cognitive_enhancement'},
        {id: "3-6",name: '其他系列', path: '/other_accessories'},
    ]},
    
    {id: '5',name: '学习路线', path: '',children:[
        {id: "7-1",name: '图灵工控一期：上位机+AI', path: '/gk1_path'},
        {id: "7-2",name: '图灵工控二期：视觉运控', path: '/gk2_path'},
        {id: "7-3",name: '图灵工控三期：MES', path: '/gk3_path'},
    ]},
    
    // {id: '6',name: '学员服务', path: '/gk_service'},
    // {id: '7', name: '视频中心', path: '/video' },
    {id: '9',name: '源码案例', path: '/case_presentation',children:[
        // {id: "6-1",name: '案例展示', path: '/case_presentation'},
    ]},
    {id: '99',name: '关于我们', path: '/company_profile',children: [
        // {id: "99-1",name: '公司简介', path: '/company_profile'},
        // {id: "99-2",name: '创始成员', path: '/company_employee'},
    ]},
]