<!--
 * @Description: 首页
 * @Author: AlanGao
 * @Date: 2024-05-16 21:42:22
 * @LastEditors: AlanGao
 * @LastEditTime: 2024-05-18 21:12:27
-->
<template>
  <router-view />
</template>

<style>
* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}
.w1400 {
  width: 1400px;
  margin: 0 auto;
}
a {
  text-decoration: none;
}
.clearfix::before,
.clearfix::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.slick-dots {
  bottom: 20px !important;
}
.slick-dots li button:before {
  color: #ccc !important;
  font-size: 16px !important;
}
.slick-dots li.slick-active button:before {
  color: #fff !important;
}
@media screen and (max-width: 1520px) {
  .w1400 {
    width: 1200px;
  }
  .logo img {
    width: 120% !important;
  }
}
@media screen and (max-width: 767px) {
  .w1400 {
    width: 100%;
  }
}
</style>

<script type="text/javascript">
  (function(d, t) {
      var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
      v.onload = function() {
        window.voiceflow.chat.load({
          verify: { projectID: '679cff9a418106dd481ca5bf' },
          url: 'https://general-runtime.voiceflow.com',
          versionID: 'production'
        });
      }
      v.src = "https://cdn.voiceflow.com/widget-next/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
  })(document, 'script');
</script>

