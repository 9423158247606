<!--
 * @Description: 中间公共部分组件
 * @Author: AlanGao
 * @Date: 2024-05-16 00:56:08
 * @LastEditors: AlanGao
 * @LastEditTime: 2024-05-23 23:40:09
-->
<template>
  <div class="container">
    <div class="w1400">
        <img :src="props.url" class="banner" />
    </div>
    
  </div>
  <div class="main">
    <div class="main_title">
      <div class="main_container">
        <ul>
          <li
            v-for="item in result.title"
            :key="item.id"
            :style="{
              'border-bottom':
                item.path === route.path ? '1px solid #184a8f' : '',
            }"
          >
            <router-link
              :to="item.path"
              :style="{ color: item.path === route.path ? '#184a8f' : '' }"
              >{{ item.name }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="main_content">
      <div class="main_breadcrumb">
        <img src="/assets/image/center/shouye.png" />
        <span>当前位置：</span>
        <el-breadcrumb separator="-">
          <el-breadcrumb-item :to="{ path: '/home' }"
            >网站首页</el-breadcrumb-item
          >
          <!-- :to="{ path: item.path } -->
          <el-breadcrumb-item v-for="item in result.breadcrumb" :key="item.id">
            {{ item.name }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <slot></slot>
      <!-- h5底部 -->
      <div class="mb_footerContent">
        <span>
          版权所有：图灵技术官
        </span>
        <span>
          技术支持：图灵技术官
        </span>
        <span>
          联系方式：zhy_cxx
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, watch, reactive } from "vue";
import { useRoute } from "vue-router";
import { menuData } from "/public/assets/data/menuData.js";

let props = defineProps({
  url: {
    type: String,
    default: "/assets/image/center/about_ban.jpg",
  },
});
let route = useRoute();
// 获取数据
const findMenuByPath = (menu, path) => {
  const data = [];
  const breadcrumb = [];
  function findItem(menu, path) {
    for (const item of menu) {
      if (item.path === path) {
        data.push(item);
        breadcrumb.push(item);
        return data;
      }
      if (item.children) {
        for (let child of item.children) {
          if (child.path === path) {
            breadcrumb.push({ name: item.name, path: item.path, id: item.id });
            breadcrumb.push(child);
            return item.children;
          }
        }
      }
    }
    return null;
  }
  const title = findItem(menu, path);
  return title ? { title, breadcrumb } : null;
};
let result = reactive(findMenuByPath(menuData, route.path));
</script>

<style scoped>
.container{
    width: 100%;
    min-width: 1200px;
    /* height: 300px; */
    text-align: center;
    overflow: hidden;
}
.banner{
    width: 100%;
}
.main {
    width: 100%;
    min-width: 1200px;
    height: auto;
    margin-bottom: 70px;
    overflow: hidden;
}
.main_title {
  width: 100%;
  height: 75px;
  display: table;
  background: #f2f2f2;
}
.main_container {
  width: 1320px;
  margin: 0 auto;
  text-align: center;
}
.main_container ul {
  width: auto;
  height: 75px;
  text-align: center;
  display: table;
  max-width: 100%;
  margin: 0px auto;
}
.main_container ul li {
  float: left;
  width: auto;
  text-align: center;
  height: 50px;
  margin-top: 20px;
}
.main_container ul li a {
  font-size: 16px;
  color: #666;
  border-right: 1px solid #cccccc;
  width: 100%;
  display: block;
  padding: 0px 20px;
}
.main_container ul li:last-child a {
  border-right: 0;
}
.main_content {
  width: 1320px;
  margin: 0 auto;
}
.main_breadcrumb {
  width: 100%;
  height: 35px;
  color: #303133;
  font-size: 12px;
  /* line-height: 35px; */
  margin: 15px 0 20px;
  display: flex;
  align-items: center;
}
.main_breadcrumb img {
  margin-right: 8px;
  width: 16px;
  height: 14px;
}
.el-breadcrumb {
  display: inline-block;
}
:deep(.el-breadcrumb__inner.is-link) {
  font-weight: normal !important;
}
@media screen and (max-width: 1520px) {
  .main_content,
  .main_container {
    width: 1200px;
  }
}
.mb_footerContent {
  display: none;
}
@media screen and (max-width: 767px) {
  .container,
  .main_title{
    display: none;
  }
  .main_content {
    width: 100%;
  }
  .main {
    min-width: unset;
  }
  .main_breadcrumb {
    padding-left: 4px;
  }
  .mb_footerContent {
      display: block;
      width: 100%;
      font-size: 13px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #666;
      margin-top: 30px;
      margin-bottom: 80px;
    }
    .mb_footerContent span {
      padding: 2px;
    }
}
</style>
